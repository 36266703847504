<template>
  <v-container fluid class="pt-0 fill-height">
    <v-row justify="center" style="height: 100%; display: flex; flex-direction: column; flex-wrap: nowrap">
      <v-dialog v-model="show_dialog" width="500">
        <v-card>
          <v-card-text class="pt-3">
            <v-row>
              <v-col cols="12" md="6">
                <date_input
                    v-model="filter_data.date_from"
                    label="Період з"
                    :required="false"
                    :time="true"
                />
              </v-col>
              <v-col cols="12" md="6">
                <date_input
                    v-model="filter_data.date_to"
                    label="Період по"
                    :required="false"
                    :time="true"
                />
              </v-col>
              <v-col cols="12">
                <select_input
                    v-model="filter_data.update_module_type"
                    :import-getter="{ name: 'update_information_module_type' }"
                    :input-value-as-value="false"
                    clearable
                    :disabled="true"
                    :required="false"
                    label="Тип модуля/розділу: "
                />
              </v-col>
              <v-col cols="12">
                <select_input
                    v-model="filter_data.update_information_status"
                    :import-getter="{ name: 'update_information_status' }"
                    :input-value-as-value="false"
                    clearable
                    :required="false"
                    label="Важливість оновлення: "
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small depressed class="success" @click="fetchData">ОК</v-btn>
            <v-btn small depressed @click="closeDialog">Закрити</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-col cols="12" style="flex: 0">
        <v-card>
          <v-toolbar tile elevation="0" outlined dense style="border: none"
          :height="$vuetify.breakpoint.xs ? 90 : 54">
            <v-toolbar-title class="font-weight-medium" style="font-size: 1.1rem">
              Список оновлень платформи
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="shrink">
              <v-row class="align-center">
                <v-btn depressed fab width="44" height="44" @click="openDialog">
                  <v-icon>
                    mdi-filter-cog-outline
                  </v-icon>
                </v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card>
      </v-col>
      <v-col cols="12" style="flex: 1">
        <v-expansion-panels inset
                            v-model="panel"
        >
          <v-expansion-panel
              v-for="(item,i) in items"
              :key="i"
          >
            <v-expansion-panel-header v-slot:default>
              <div>
                <div>
                  <v-chip label small class="mr-3"
                          :color="getUpdateInformationStatus(item.update_information_status, 'color')">
                    {{ getUpdateInformationStatus(item.update_information_status, 'text') }}
                  </v-chip>

                  <v-chip label small>
                    {{ item.create_date | formatDate }}
                  </v-chip>
                </div>
                <div class="mt-3 grey--text text--darken-3" style="font-size: .94rem;">
                  {{ item.title }}
                </div>
              </div>

            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div style="font-size: .86rem">
                {{ item.description }}
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import updateInformationAPI from '@/utils/axios/update-information'
import {getUpdateInformationStatus} from "@/utils/icons"
import {mapGetters} from "vuex";

export default {
  name: "UpdateInformation",
  props: {
    update_module_type: {
      type: String,
      default: null
    }
  },
  components: {
    date_input: () => import("@/components/accounting/input/document_date_input.vue"),
    select_input: () => import("@/components/accounting/input/document_select_input.vue")
  },
  data() {
    return {
      show_dialog: false,
      panel: 0,
      items: [],
      filter_data: {
        date_from: null,
        date_to: null,
        update_module_type: null,
        update_information_status: null
      }
    }
  },
  computed: {
    ...mapGetters({
      accounting: 'isAccounting',
      legacy: 'isLegacy',
    })
  },
  methods: {
    getUpdateInformationStatus,
    openDialog() {
      this.filter_data.update_module_type = this.getModuleFilterValue()

      this.show_dialog = true
    },
    closeDialog() {
      this.show_dialog = false

      this.filter_data.date_from = null
      this.filter_data.date_to = null
      this.filter_data.update_information_status = null
      this.filter_data.update_module_type = this.getModuleFilterValue()
    },
    fetchData() {
      const payload = {
        ...this.filter_data
      }
      updateInformationAPI.get_all(payload)
          .then(response => response.data)
          .then(data => this.items = data)
          .finally(() => {
            this.show_dialog = false
          })
    },
    getModuleFilterValue() {
      if (!this.accounting && !this.legacy) {
        return 'flat_accounting'
      }
      if (!this.accounting && this.legacy) {
        return 'flat_legacy_accounting'
      }
      if (this.accounting && !this.legacy) {
        return 'accounting'
      }
      return 'accounting'
    },
  },
  created() {
    this.filter_data.update_module_type = this.getModuleFilterValue()

    if (this.update_module_type) {
      this.filter_data.update_module_type = this.update_module_type
    }
    this.fetchData()
  }
}
</script>


<style scoped lang="scss">

</style>